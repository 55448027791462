.auto-diary-module {
  padding: 20px;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.auto-diary-module > div:first-child {
  display: flex;
  gap: 20px;
  height: calc(100vh - 250px);
  margin-bottom: 20px;
}

/* Chat container */
.auto-diary-module .conversation-container {
  border: 1px solid #ccc;
  padding: 10px;
  height: calc(100% - 80px);
  overflow: auto;
  max-width: 100%;
  background-color: white;
  border-radius: 4px;
}

/* Calendar container */
.auto-diary-module .calendar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.auto-diary-module .calendar-container .fc {
  flex: 1;
  min-height: calc(100% - 120px);
}

.auto-diary-module .calendar-container .fc-view-harness {
  height: 100% !important;
}

.refresh-button-container {
  margin-top: 10px;
  padding-bottom: 10px;
  text-align: right;
}

.refresh-button {
  background-color: #4a5568;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.refresh-button:hover {
  background-color: #2d3748;
}

.auto-diary-module h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.uninstall-buttons {
  margin-top: auto;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.uninstall-button, .uninstall-keep-data-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.uninstall-button {
  background-color: #d9534f;
  color: white;
}

.uninstall-keep-data-button {
  background-color: #f0ad4e;
  color: white;
}

.uninstall-button:hover {
  background-color: #c9302c;
}

.uninstall-keep-data-button:hover {
  background-color: #ec971f;
}

.preview-event {
  border: 2px solid #3b82f6 !important;
  border-radius: 4px;
  opacity: 0.85;
}