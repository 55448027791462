/* client/src/App.css */
.chat-window {
  border: 1px solid #ccc;
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
}

.message {
  margin-bottom: 10px;
}

.message.user {
  text-align: right;
  background-color: #e0f7fa;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}

.message.ai {
  text-align: left;
  background-color: #f1f8e9;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}

.chat-input {
  display: flex;
  margin-top: 10px;
}

.chat-input input {
  flex: 1;
  padding: 5px;
}

.chat-input button {
  padding: 5px 10px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}