.potential-mentions-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.mentions-list {
  display: grid;
  gap: 16px;
  margin-top: 16px;
}

.mention-card {
  background: white;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
}

.mention-card h3 {
  margin: 0 0 8px 0;
}

.potential-names {
  margin-top: 12px;
}

.potential-names ul {
  margin: 8px 0;
  padding-left: 20px;
}

.badge {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #007bff;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.people-list {
  margin-top: 24px;
}

/* Styles for the Close People Module */

.close-people-module {
  padding: 20px;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
}

.close-people-module h1 {
  color: #333;
  margin-bottom: 20px;
}

.close-people-module .uninstall-button {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

.close-people-module .uninstall-button:hover {
  background-color: #c9302c;
}

.people-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.person-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.person-card h2 {
  margin-top: 0;
  color: #007acc;
}

.person-card .info {
  margin-bottom: 10px;
}

.person-card .info label {
  font-weight: bold;
}

.person-card .edit-button, .person-card .save-button {
  background-color: #007acc;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.person-card .edit-button:hover, .person-card .save-button:hover {
  background-color: #005f99;
}

.potential-mentions-section {
  margin-bottom: 30px;
}

.potential-mentions-section .mention-card {
  background-color: #fffbcc;
  border: 1px solid #ffe58a;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.potential-mentions-section .mention-card .badge {
  background-color: #ffcc00;
  color: #fff;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.interactions-chart {
  margin-top: 40px;
}

.interactions-chart h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.edit-section label, .details-section .info {
  display: block;
  margin-bottom: 8px;
}

.edit-section input[type="text"], .edit-section textarea {
  width: 100%;
  padding: 6px;
  margin-top: 4px;
  box-sizing: border-box;
}

.show-events-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
}

.show-events-button:hover {
  background-color: #218838;
}

.events-section {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.events-list {
  max-height: 200px;
  overflow-y: auto;
}

.event-item {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.event-summary {
  font-weight: bold;
}

.event-date {
  font-size: 0.9em;
  color: #666;
}

.show-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.show-more-button:hover {
  background-color: #5a6268;
}

.name-replacement-tool {
  margin: 20px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.search-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-section input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-button, .replace-button {
  background-color: #007acc;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:disabled, .replace-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.matching-events {
  margin-top: 20px;
}

.event-item {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.toggle-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #eee;
  cursor: pointer;
}

.toggle-button.selected {
  background-color: #007acc;
  color: white;
}

.event-details {
  flex: 1;
}
