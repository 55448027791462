/* FullCalendar styles */
.fc {
  /* Ensure calendar takes full width */
  width: 100%;
}

.fc .fc-toolbar {
  /* Make toolbar more responsive */
  flex-wrap: wrap;
  gap: 0.5rem;
}

.fc .fc-button {
  /* Match your app's button styling */
  background-color: #3b82f6;
  border: none;
}

.fc .fc-button:hover {
  background-color: #2563eb;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #1d4ed8;
}

/* Add blur effect for event titles */
.blur-events .fc-event-title {
  filter: blur(4px);
}

.blur-events .fc-event-title:hover {
  filter: none;
  transition: filter 0.2s ease;
}
