.correlation-table-container {
  margin: 20px 0;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.correlation-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.correlation-table th,
.correlation-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
}

.correlation-table th {
  background-color: #f9fafb;
  font-weight: 600;
  color: #374151;
}

.correlation-table tr:hover {
  background-color: #f9fafb;
}

.correlation-strength-high {
  color: #059669;
  font-weight: 600;
}

.correlation-strength-moderate {
  color: #0284c7;
}

.correlation-strength-low {
  color: #6b7280;
}

.correlation-negative {
  color: #dc2626;
}

.matches-scrollbox {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background-color: #f9fafb;
}

.match-item {
  padding: 8px 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 0.875rem;
}

.match-item:last-child {
  border-bottom: none;
}

.match-item:hover {
  background-color: #f3f4f6;
}

.correlation-table button {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.correlation-table button:hover {
  background-color: rgba(0, 0, 0, 0.05);
} 